import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Link from "@material-ui/core/Link";
import InputLabel from "@material-ui/core/InputLabel";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CreateIcon from '@material-ui/icons/Create';
import FileCopyIcon from "@material-ui/icons/FileCopy";

import { i18n } from "../../translate/i18n";
import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import MarkdownWrapper from "../MarkdownWrapper";
import { CardHeader } from "@material-ui/core";
import { ContactForm } from "../ContactForm";
import ContactModal from "../ContactModal";
import { ContactNotes } from "../ContactNotes";
import { TagsContainer } from "../TagsContainer";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    display: "flex",
  },
  header: {
    display: "flex",
    backgroundColor: theme.palette.tabHeaderBackground,
    alignItems: "center",
    padding: theme.spacing(0, 1),
    minHeight: "72px",
    justifyContent: "flex-start",
    borderLeft: 0,
    borderTop: 0,
    borderRight: 0,
  },
  content: {
    display: "flex",
    border: 0,
    backgroundColor: theme.palette.background.paper,
    flexDirection: "column",
    height: "100%",
    overflowX: "hidden",
    overflowY: "scroll",
    flexGrow: 1,
    ...theme.scrollbarStyles,
  },
  subContent: {
    marginLeft: 7,
    height: "100%",
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
    border: 0,
  },
  contactAvatar: {
    width: 60,
    height: 60,
  },
  contactAvatarSkeleton: {
    width: 60,
    height: 60,
    margin: 15,
  },
  contactName: {
    display: 'flex',
    alignItems: 'center',
  },
  contactIcon: {
    fontSize: 20,
  },
  contactIcon2: {
    fontSize: 16,
  },
  contactDetails: {
    border: 0,
    padding: 8,
    display: "flex",
    flexDirection: "column",
  },
  contactDetailsList: {
    border: 0,
    padding: 8,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  contactExtraInfo: {
    borderLeft: 0,
    borderTop: 0,
    marginTop: 0,
    padding: 6,
  },
  tagsContainer: {
    borderLeft: 0,
    borderTop: 0,
    borderRight: 0,
    marginTop: 0,
    paddingTop: 8,
    paddingRight: 8,
    paddingBottom: 10,
    padding: 8,
    display: "flex",
    flexDirection: "column",
  },
  contactNotes: {
    borderLeft: 0,
    borderTop: 0,
    borderRight: 0,
    marginTop: 0,
    paddingLeft: 8,
    paddingTop: 8,
    paddingBottom: 8,
    display: "flex",
    flexDirection: "column",
  },
  contactNotesSkeleton: {
    borderLeft: 0,
    borderTop: 0,
    borderRight: 0,
    borderBottom: 0,
    marginTop: 0,
    border: 0,
    padding: 8,
    display: "flex",
    flexDirection: "column",
  },
}));

const ContactDrawer = ({
  open,
  handleDrawerClose,
  contact,
  ticket,
  loading,
}) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const drawerWidth = isSmallScreen ? "100%" : 320;

  const [modalOpen, setModalOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setOpenForm(false);
  }, [open, contact]);

  const handleCopyNumber = () => {
    navigator.clipboard.writeText(contact.number);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        PaperProps={{
          style: { position: "absolute", width: drawerWidth },
        }}
        BackdropProps={{ style: { position: "absolute" } }}
        ModalProps={{
          container: document.getElementById("drawer-container"),
          style: { position: "absolute" },
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Paper elevation={0} square variant="outlined" className={classes.header}>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon className={classes.contactIcon} />
          </IconButton>
          <Typography style={{ justifySelf: "center" }}>
            {i18n.t("contactDrawer.header")}
            <Typography style={{ fontSize: 13, display: "flex", alignItems: "center" }}>
              <Link href={`tel:${contact.number}`}>{contact.number}</Link>
              <IconButton
                style={{ padding: 0, marginLeft: 5 }}
                onClick={handleCopyNumber}
              >
                <FileCopyIcon className={classes.contactIcon2} />
              </IconButton>
              {copied && <span>{i18n.t("contactDrawer.copiedText")}</span>}
            </Typography>
          </Typography>
          <IconButton style={{ marginLeft: "auto" }} onClick={() => setModalOpen(!openForm)}>
            <CreateIcon className={classes.contactIcon} />
            {(contact.id && openForm) && <ContactForm initialContact={contact} onCancel={() => setOpenForm(false)} />}
          </IconButton>
        </Paper>
        {loading ? (
          <ContactDrawerSkeleton classes={classes} />
        ) : (
          <div className={classes.content}>
            <Paper square variant="outlined" className={classes.subContent}>
              <Paper square variant="outlined" className={classes.tagsContainer}>
                <Typography variant="subtitle1" style={{ marginBottom: 8 }}>
                  {i18n.t("contactDrawer.tagsContainer.title")}
                </Typography>
                <TagsContainer ticket={ticket} />
              </Paper>
              <Paper square variant="outlined" className={classes.contactNotes}>
                <Typography variant="subtitle1" style={{ marginBottom: 8 }}>
                  {i18n.t("contactDrawer.appointmentsModal.title")}
                </Typography>
                <ContactNotes ticket={ticket} style={{ height: "100%", overflow: "hidden" }} />
              </Paper>
              <Paper square variant="outlined" className={classes.contactDetails}>
                <ContactModal
                  open={modalOpen}
                  onClose={() => setModalOpen(false)}
                  contactId={contact.id}
                ></ContactModal>
                <Typography variant="subtitle1" style={{ marginBottom: "12px" }}>
                  {i18n.t("contactDrawer.extraInfo")}
                </Typography>
                {contact.email && (
                  <Paper
                    square
                    variant="outlined"
                    className={classes.contactDetailsList}
                  >
                    <InputLabel>{i18n.t("contactDrawer.email")}</InputLabel>
                    <Typography component="div" noWrap style={{ paddingTop: 2 }}>
                      <Link href={`mailto:${contact.email}`}>{contact.email}</Link>
                    </Typography>
                  </Paper>
                )}
                {contact?.extraInfo?.map(info => (
                  <Paper
                    key={info.id}
                    square
                    variant="outlined"
                    className={classes.contactDetailsList}
                  >
                    <InputLabel>{info.name}</InputLabel>
                    <Typography component="div" noWrap style={{ paddingTop: 2 }}>
                      <MarkdownWrapper>{info.value}</MarkdownWrapper>
                    </Typography>
                  </Paper>
                ))}
              </Paper>
            </Paper>
          </div>
        )}
      </Drawer>
    </>
  );
};

export default ContactDrawer;
