import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel"; 
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { FormControl } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { AuthContext } from "../../context/Auth/AuthContext";
import { isArray } from "lodash";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },
    btnWrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const ScheduleSchema = Yup.object().shape({
    body: Yup.string()
        .min(5, i18n.t("tooShort"))
        .required("Obligatorio"),
    contactId: Yup.number().required(i18n.t("required")),
    sendAt: Yup.string().required(i18n.t("required")),
});

const ScheduleModal = ({ open, onClose, scheduleId, contactId, cleanContact, reload }) => {
    const classes = useStyles();
    const history = useHistory();
    const { user } = useContext(AuthContext);

    const initialState = {
        body: "",
        contactId: "",
        sendAt: moment().add(1, "hour").format("YYYY-MM-DDTHH:mm"),
        sentAt: "",
        queueId: null,
        queueOptions: false,  // Añadido aquí para manejar el valor inicial
    };

    const initialContact = {
        id: "",
        name: "",
    };

    const initialQueue = {
        id: "",
        name: "",
    };

    const [schedule, setSchedule] = useState(initialState);
    const [currentContact, setCurrentContact] = useState(initialContact);
    const [contacts, setContacts] = useState([initialContact]);
    const [queues, setQueues] = useState([initialQueue]);
    const [currentQueue, setCurrentQueue] = useState(initialQueue);
    const [showQueueOptions, setShowQueueOptions] = useState(false); // Para controlar la visibilidad del checkbox

    useEffect(() => {
        const { companyId } = user;
        if (open) {
            try {
                (async () => {
                    const { data: contactList } = await api.get("/contacts/list", { params: { companyId } });
                    let customList = contactList.map((c) => ({ id: c.id, name: c.name }));
                    if (isArray(customList)) {
                        setContacts([{ id: "", name: "" }, ...customList]);
                    }

                    const { data: queueList } = await api.get("/queue", { params: { companyId } });
                    let customQueueList = queueList.map((q) => ({ id: q.id, name: q.name, specification: q.specification, type: q.type }));
                    if (isArray(customQueueList)) {
                        setQueues([{ id: "", name: "", specification: "", type: "" }, ...customQueueList]);
                    }

                    if (contactId) {
                        setSchedule((prevState) => {
                            return { ...prevState, contactId };
                        });
                    }

                    if (!scheduleId) return;

                    const { data } = await api.get(`/schedules/${scheduleId}`);
                    setSchedule((prevState) => {
                        return { ...prevState, ...data, sendAt: moment(data.sendAt).format("YYYY-MM-DDTHH:mm") };
                    });
                    setCurrentContact(data.contact);
                    setCurrentQueue(data.queue);
                })();
            } catch (err) {
                toastError(err);
            }
        }
    }, [scheduleId, contactId, open, user]);

    const handleClose = (resetForm) => {
        onClose();
        setSchedule(initialState);
        setCurrentContact(initialContact);
        setCurrentQueue(initialQueue);
        setShowQueueOptions(false);  // Restablecer el estado del checkbox
        resetForm();  // Restablecer el formulario
    };

    const handleQueueChange = (e, queue, setFieldValue) => {
        setCurrentQueue(queue ? queue : initialQueue);

        if (queue && queue.type === "simple") {
            setShowQueueOptions(true); // Mostrar el checkbox si la queue es de tipo "simple"
        } else {
            setShowQueueOptions(false); // Ocultar el checkbox si no es "simple"
            setFieldValue("queueOptions", false); // Restablecer el valor del checkbox a false
        }
    };

    const handleSaveSchedule = async (values) => {
        const scheduleData = {
            ...values,
            queueId: currentQueue?.id || null,
            userId: user.id,
            sendAt: moment(values.sendAt).utc().format("YYYY-MM-DDTHH:mm:ss"),
        };

        try {
            if (scheduleId) {
                await api.put(`/schedules/${scheduleId}`, scheduleData);
            } else {
                await api.post("/schedules", scheduleData);
            }
            toast.success(i18n.t("scheduleModal.success"));
            if (typeof reload === "function") {
                reload();
            }
            if (contactId) {
                if (typeof cleanContact === "function") {
                    cleanContact();
                    history.push("/schedules");
                }
            }
        } catch (err) {
            toastError(err);
        }
        setCurrentContact(initialContact);
        setCurrentQueue(initialQueue);
        setSchedule(initialState);
        handleClose();
    };

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth scroll="paper">
                <DialogTitle id="form-dialog-title">
                    <span style={{ float: "left" }}>{i18n.t("scheduleModal.title")}</span>
                    <span style={{ fontSize: "16px", float: "right" }}>
                        {schedule.status === "PENDING"
                            ? i18n.t("scheduleModal.status.pending")
                            : schedule.status === "SENT"
                            ? i18n.t("scheduleModal.status.sent")
                            : schedule.status === "SCHEDULED"
                            ? i18n.t("scheduleModal.status.scheduled")
                            : schedule.status === "ERROR"
                            ? i18n.t("scheduleModal.status.error")
                            : ""}
                    </span>
                </DialogTitle>
                <Formik
                    initialValues={schedule}
                    enableReinitialize={true}
                    validationSchema={ScheduleSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveSchedule(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ touched, errors, isSubmitting, setFieldValue, resetForm }) => (
                        <Form>
                            <DialogContent dividers>
                                <div className={classes.multFieldLine}>
                                    <FormControl variant="outlined" fullWidth>
                                        <Autocomplete
                                            fullWidth
                                            value={currentContact}
                                            options={contacts}
                                            onChange={(e, contact) => {
                                                const contactId = contact ? contact.id : "";
                                                setSchedule({ ...schedule, contactId });
                                                setCurrentContact(contact ? contact : initialContact);
                                            }}
                                            getOptionLabel={(option) => option.name}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Contacto" />}
                                        />
                                    </FormControl>
                                </div>
                                <br />
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        rows={9}
                                        multiline={true}
                                        label={i18n.t("scheduleModal.form.body")}
                                        name="body"
                                        error={touched.body && Boolean(errors.body)}
                                        helperText={touched.body && errors.body}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                                <br />
                                <div className={classes.multFieldLine}>
                                    <FormControl variant="outlined" fullWidth>
                                        <Autocomplete
                                            fullWidth
                                            value={currentQueue}
                                            options={queues}
                                            onChange={(e, queue) => handleQueueChange(e, queue, setFieldValue)}
                                            getOptionLabel={(option) => option.name && option.specification ? `${option.name} - ${option.specification}` : ""}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            renderInput={(params) => (
                                                <TextField 
                                                    {...params} 
                                                    variant="outlined" 
                                                    placeholder="Cola" 
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        inputProps: {
                                                            ...params.inputProps,
                                                            placeholder: 'Cola',  
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </div>
                                <br />
                                {showQueueOptions && (
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  <FormControlLabel
                                    control={
                                      <Field
                                        as={Checkbox}
                                        name="queueOptions"
                                        color="primary"
                                      />
                                    }
                                    label="Enviar con las opciones de la cola"
                                    labelPlacement="start"
                                  />
                                </div>  
                                )}
                                <br />
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("scheduleModal.form.sendAt")}
                                        type="datetime-local"
                                        name="sendAt"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={touched.sendAt && Boolean(errors.sendAt)}
                                        helperText={touched.sendAt && errors.sendAt}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleClose(resetForm)} color="secondary" disabled={isSubmitting} variant="outlined">
                                    {i18n.t("scheduleModal.buttons.cancel")}
                                </Button>
                                {(schedule.sentAt === null || schedule.sentAt === "") && (
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={isSubmitting}
                                        variant="contained"
                                        className={classes.btnWrapper}
                                    >
                                        {scheduleId
                                            ? `${i18n.t("scheduleModal.buttons.okEdit")}`
                                            : `${i18n.t("scheduleModal.buttons.okAdd")}`}
                                        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </Button>
                                )}
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default ScheduleModal;
