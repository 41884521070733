import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { i18n } from "../../translate/i18n";
import { Grid } from "@material-ui/core";

const ContactDrawerSkeleton = ({ classes }) => {
	return (
		<div className={classes.content}>
            <Paper square variant="outlined" className={classes.tagsContainer}>
              <Typography variant="subtitle1" style={{ marginBottom: 8 }}>
                {i18n.t("contactDrawer.tagsContainer.title")}
              </Typography>
              	<Paper square variant="outlined" className={classes.contactNotesSkeleton}>
              	    <Skeleton animation="wave" height={20} width={130} />
					<Typography variant="subtitle1" style={{ color: '#A0A0A0', }}>
                        {i18n.t("tags.placeholder1")}
                    </Typography>
				</Paper>
            </Paper>
            
            <Paper square variant="outlined" className={classes.tagsContainer}>
              <Typography variant="subtitle1" style={{ marginBottom: 8 }}>
                {i18n.t("contactDrawer.tagsContainer.title")}
              </Typography>
              	<Paper square variant="outlined" className={classes.contactNotesSkeleton}>
              	    <Skeleton animation="wave" height={20} width={130} />
					<Typography variant="subtitle1" style={{ color: '#A0A0A0', }}>
                        {i18n.t("contactDrawer.appointmentsModal.textarea")}
                    </Typography>
				</Paper>
            </Paper>
            
            <Paper square variant="outlined" className={classes.tagsContainer}>
              <Typography variant="subtitle1" style={{ marginBottom: 8 }}>
                {i18n.t("contactDrawer.extraInfo")}
              </Typography>
              	<Paper square variant="outlined" className={classes.contactNotesSkeleton}>
					<Skeleton animation="wave" height={20} width={130} />
				</Paper>
            </Paper>            
			
		</div>
	);
};

export default ContactDrawerSkeleton;
