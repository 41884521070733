import React, { useState, useEffect, useRef, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { head, isArray } from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import { i18n } from "../../translate/i18n";
import moment from "moment";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { AuthContext } from "../../context/Auth/AuthContext";
import ConfirmationModal from "../ConfirmationModal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },
  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  tabs: {
    background: theme.mode === "light" ? "#f2f2f2" : "#7f7f7f",
    border: "1px solid #e6e6e6",
    borderRadius: 2,
  },
  lightGrayLabel: {
    color: "#B0B0B0",
  },  
}));

const CampaignSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

const CampaignModal = ({
  open,
  onClose,
  campaignId,
  initialValues,
  onSave,
  resetPagination,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);
  const { user } = useContext(AuthContext);
  const { companyId } = user;

const initialState = {
  name: "",
  message1: "",
  message2: "",
  message3: "",
  message4: "",
  message5: "",
  status: "INATIVA", // INATIVA, PROGRAMADA, EM_ANDAMENTO, CANCELADA, FINALIZADA,
  scheduledAt: "",
  whatsappIds: [],
  contactListId: "",
  companyId,
  queueId: null,
  queueOptions: false,
  messageInterval: 0,
  longerIntervalAfter: 0,
  greaterInterval: 0,    
};


  const initialQueue = {
    id: "",
    name: "",
  };

  const [campaign, setCampaign] = useState(initialState);
  const [whatsapps, setWhatsapps] = useState([]);
  const [selectedWhatsappIds, setSelectedWhatsappIds] = useState([]); // Estado para múltiples WhatsApps
  const [contactLists, setContactLists] = useState([]);
  const [queues, setQueues] = useState([initialQueue]);
  const [currentQueue, setCurrentQueue] = useState(initialQueue);  
  const [messageTab, setMessageTab] = useState(0);
  const [attachment, setAttachment] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [campaignEditable, setCampaignEditable] = useState(true);
  const [showQueueOptions, setShowQueueOptions] = useState(false);
  const attachmentFile = useRef(null);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

useEffect(() => {
  if (isMounted.current) {
    // Si hay valores iniciales, los asignamos al estado 'campaign'
    if (initialValues) {
      setCampaign((prevState) => {
        return { ...prevState, ...initialValues };
      });
    }

    // Obtenemos las listas de contactos
    api.get(`/contact-lists/list`, { params: { companyId } })
      .then(({ data }) => setContactLists(data));

    // Obtenemos las conexiones de WhatsApp
    api.get(`/whatsapp/connected`, { params: { companyId } })
      .then(({ data }) => setWhatsapps(data));

    // Obtenemos las queues desde el API
    api.get(`/queue`, { params: { companyId } }).then(({ data: queueList }) => {
      let customQueueList = queueList.map((q) => ({
        id: q.id,
        name: q.name,
        specification: q.specification,
        type: q.type,
      }));

      // Si es una lista de colas válida, la asignamos
      if (isArray(customQueueList)) {
        setQueues([{ id: "", name: "" }, ...customQueueList]);
      }

      // Si no estamos en modo edición, salimos de aquí
      if (!campaignId) return;

      // Obtenemos la campaña por su ID
api.get(`/campaigns/${campaignId}`).then(({ data }) => {
  setCampaign((prev) => {
    let prevCampaignData = Object.assign({}, prev);

    Object.entries(data).forEach(([key, value]) => {
      if (key === "scheduledAt" && value !== "" && value !== null) {
        prevCampaignData[key] = moment(value).format("YYYY-MM-DDTHH:mm");
      } else {
        prevCampaignData[key] = value === null ? "" : value;
      }
    });

    // Asignar correctamente la queue recuperada
    const foundQueue = customQueueList.find(q => q.id === data.queueId);
    if (foundQueue) {
      setCurrentQueue(foundQueue); // Aquí establecemos la queue actual basada en el ID
    }

    setSelectedWhatsappIds(data.whatsapps || []); // Preseleccionar los WhatsApp conectados
    prevCampaignData.queueOptions = data.queueOptions || false; // Aquí recuperamos el valor de queueOptions
    return prevCampaignData;
  });
}).catch(err => toastError(err)); // Manejar errores si falla la llamada de API.

    }).catch(err => toastError(err)); // Agregar manejo de errores si falla la llamada de API.
  }
}, [campaignId, open, initialValues, companyId]);


  useEffect(() => {
    const now = moment();
    const scheduledAt = moment(campaign.scheduledAt);
    const moreThenAnHour =
      !Number.isNaN(scheduledAt.diff(now)) && scheduledAt.diff(now, "hour") > 1;
    const isEditable =
      campaign.status === "INATIVA" ||
      (campaign.status === "PROGRAMADA" && moreThenAnHour);

    setCampaignEditable(isEditable);
  }, [campaign.status, campaign.scheduledAt]);

  useEffect(() => {
    if (currentQueue && currentQueue.type === "simple") {
      setShowQueueOptions(true);
    } else {
      setShowQueueOptions(false);
    }
  }, [currentQueue]);

  const handleClose = () => {
    onClose();
    setCampaign(initialState);
    setCurrentQueue(initialQueue);    
    setSelectedWhatsappIds([]); // Restablecer WhatsApps seleccionados
  };

  const handleAttachmentFile = (e) => {
    const file = head(e.target.files);
    if (file) {
      setAttachment(file);
    }
  };

  const handleSaveCampaign = async (values) => {
    try {
      const dataValues = {};
      Object.entries(values).forEach(([key, value]) => {
        if (key === "scheduledAt" && value !== "" && value !== null) {
          // Convertir la fecha de programación a UTC antes de enviarla
          dataValues[key] = moment(value).utc().format("YYYY-MM-DDTHH:mm:ss");
        } else {
          dataValues[key] = value === "" ? null : value;
        }
      });

      dataValues.queueId = currentQueue?.id || null; // Guardar el queueId seleccionado
      dataValues.whatsappIds = selectedWhatsappIds.map((whatsapp) => whatsapp.id); // Guardar los WhatsApps seleccionados

      if (campaignId) {
        await api.put(`/campaigns/${campaignId}`, dataValues);

        if (attachment != null) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/campaigns/${campaignId}/media-upload`, formData);
        }
        handleClose();
      } else {
        const { data } = await api.post("/campaigns", dataValues);

        if (attachment != null) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/campaigns/${data.id}/media-upload`, formData);
        }
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("campaigns.toasts.success"));
    } catch (err) {
      console.log(err);
      toastError(err);
    }
  };

  const deleteMedia = async () => {
    if (attachment) {
      setAttachment(null);
      attachmentFile.current.value = null;
    }

    if (campaign.mediaPath) {
      await api.delete(`/campaigns/${campaign.id}/media-upload`);
      setCampaign((prev) => ({ ...prev, mediaPath: null, mediaName: null }));
      toast.success(i18n.t("campaigns.toasts.deleted"));
    }
  };

  const renderMessageField = (identifier) => {
    return (
      <Field
        as={TextField}
        id={identifier}
        name={identifier}
        fullWidth
        rows={5}
        label={i18n.t(`campaigns.dialog.form.${identifier}`)}
        placeholder={i18n.t("campaigns.dialog.form.messagePlaceholder")}
        multiline={true}
        variant="outlined"
        helperText="Utilize variáveis como {nome}, {numero}, {email} ou defina variáveis personalziadas."
        disabled={!campaignEditable && campaign.status !== "CANCELADA"}
      />
    );
  };

  const cancelCampaign = async () => {
    try {
      await api.post(`/campaigns/${campaign.id}/cancel`);
      toast.success(i18n.t("campaigns.toasts.cancel"));
      setCampaign((prev) => ({ ...prev, status: "CANCELADA" }));
      resetPagination();
    } catch (err) {
      toast.error(err.message);
    }
  };

  const restartCampaign = async () => {
    try {
      await api.post(`/campaigns/${campaign.id}/restart`);
      toast.success(i18n.t("campaigns.toasts.restart"));
      setCampaign((prev) => ({ ...prev, status: "EM_ANDAMENTO" }));
      resetPagination();
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <div className={classes.root}>
      <ConfirmationModal
        title={i18n.t("campaigns.confirmationModal.deleteTitle")}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={deleteMedia}
      >
        {i18n.t("campaigns.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {campaignEditable ? (
            <>
              {campaignId
                ? `${i18n.t("campaigns.dialog.update")}`
                : `${i18n.t("campaigns.dialog.new")}`}
            </>
          ) : (
            <>{`${i18n.t("campaigns.dialog.readonly")}`}</>
          )}
        </DialogTitle>
        <div style={{ display: "none" }}>
          <input
            type="file"
            ref={attachmentFile}
            onChange={(e) => handleAttachmentFile(e)}
          />
        </div>
<Formik
  initialValues={campaign}
  enableReinitialize={true}
  validationSchema={CampaignSchema}
  onSubmit={(values, actions) => {
    setTimeout(() => {
      handleSaveCampaign(values);
      actions.setSubmitting(false);
    }, 400);
  }}
>
  {({ values, errors, touched, isSubmitting, setFieldValue }) => {
    const handleQueueChange = (e, queue) => {
      setCurrentQueue(queue ? queue : initialQueue);

      // Si no hay queue seleccionada o el tipo no es "simple", restablecer el checkbox
      if (!queue || queue.type !== "simple") {
        setShowQueueOptions(false);
        setFieldValue("queueOptions", false); // Restablecer el valor del checkbox
      } else {
        setShowQueueOptions(true);
      }
    };
    return (
      <Form>
        <DialogContent dividers>
          <Grid spacing={2} container>
            <Grid xs={12} md={9} item>
              <Field
                as={TextField}
                label={i18n.t("campaigns.dialog.form.name")}
                name="name"
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                variant="outlined"
                margin="dense"
                fullWidth
                className={classes.textField}
                disabled={!campaignEditable}
              />
            </Grid>
            <Grid xs={12} md={3} item>
              <Field
                as={TextField}
                label={i18n.t("campaigns.dialog.form.scheduledAt")}
                name="scheduledAt"
                error={touched.scheduledAt && Boolean(errors.scheduledAt)}
                helperText={touched.scheduledAt && errors.scheduledAt}
                variant="outlined"
                margin="dense"
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                className={classes.textField}
                disabled={!campaignEditable}
              />
            </Grid>
            <Grid xs={12} md={12} item>
              <Autocomplete
                multiple
                options={whatsapps}
                getOptionLabel={(option) => option.name}
                value={selectedWhatsappIds}
                onChange={(event, newValue) => {
                  setSelectedWhatsappIds(newValue);
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={index}
                      label={option.name}
                      {...getTagProps({ index })}
                      style={{
                        height: '24px',
                        margin: '2px',
                      }}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={i18n.t("campaigns.dialog.form.whatsapp")}
                    fullWidth
                    margin="dense"
                  />
                )}
                disabled={!campaignEditable}
              />
            </Grid>
            
            <Grid item xs={12} sm={4}>
              <Field
                as={TextField}
                label="Intervalo entre mensajes"
                name="messageInterval"
                type="number"
                variant="outlined"
                margin="dense"
                fullWidth
                placeholder="Ejemplo: 20"
                InputProps={{
                  inputProps: { min: 0 },
                }}
                value={values.messageInterval === 0 ? "" : values.messageInterval}
                disabled={!campaignEditable}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Field
                as={TextField}
                label="Intervalo largo después de"
                name="longerIntervalAfter"
                type="number"
                variant="outlined"
                margin="dense"
                fullWidth
                placeholder="Ejemplo: 50"
                InputProps={{
                  inputProps: { min: 0 },
                }}
                value={values.longerIntervalAfter === 0 ? "" : values.longerIntervalAfter}
                disabled={!campaignEditable}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Field
                as={TextField}
                label="Mayor intervalo"
                name="greaterInterval"
                type="number"
                variant="outlined"
                margin="dense"
                fullWidth
                placeholder="Ejemplo: 60"
                InputProps={{
                  inputProps: { min: 0 },
                }}
                value={values.greaterInterval === 0 ? "" : values.greaterInterval}
                disabled={!campaignEditable}
              />
            </Grid>
            
            <Grid xs={12} md={4} item>
              <Autocomplete
                options={contactLists}
                getOptionLabel={(option) => option.name}
                value={contactLists.find((list) => list.id === values.contactListId) || null}
                onChange={(event, newValue) => {
                  setFieldValue("contactListId", newValue ? newValue.id : "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={i18n.t("campaigns.dialog.form.contactList")}
                    placeholder={i18n.t("campaigns.dialog.form.contactList")}
                    fullWidth
                    margin="dense"
                    error={touched.contactListId && Boolean(errors.contactListId)}
                    helperText={touched.contactListId && errors.contactListId}
                  />
                )}
                disabled={!campaignEditable}
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <Autocomplete
                options={queues}
                getOptionLabel={(option) =>
                  option.name && option.specification
                    ? `${option.name} - ${option.specification}`
                    : option.name
                }
                value={currentQueue}
                onChange={handleQueueChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Queue"
                    fullWidth
                    margin="dense"
                  />
                )}
                disabled={!campaignEditable}
              />
            </Grid>
            {showQueueOptions && (            
          <Grid xs={4} item>
                <FormControlLabel
                  control={
                    <Field
                      as={Checkbox}
                      name="queueOptions"
                      color="primary"
                      checked={values.queueOptions}
                      onChange={(event) => setFieldValue("queueOptions", event.target.checked)}
                      disabled={!campaignEditable}
                    />
                  }
                  label="Enviar el mensaje con las opciones de la cola seleccionada"
                  labelPlacement="end"
                  className={classes.lightGrayLabel}
                />
          </Grid>
            )}

            <Grid xs={12} item>
              <Tabs
                value={messageTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, v) => setMessageTab(v)}
                variant="fullWidth"
                centered
                className={classes.tabs}
              >
                <Tab label="Msg. 1" index={0} />
                <Tab label="Msg. 2" index={1} />
                <Tab label="Msg. 3" index={2} />
                <Tab label="Msg. 4" index={3} />
                <Tab label="Msg. 5" index={4} />
              </Tabs>
              <Box style={{ paddingTop: 20, border: "none" }}>
                {messageTab === 0 && renderMessageField("message1")}
                {messageTab === 1 && renderMessageField("message2")}
                {messageTab === 2 && renderMessageField("message3")}
                {messageTab === 3 && renderMessageField("message4")}
                {messageTab === 4 && renderMessageField("message5")}
              </Box>
            </Grid>

            {(campaign.mediaPath || attachment) && (
              <Grid xs={12} item>
                <Button startIcon={<AttachFileIcon />}>
                  {attachment != null ? attachment.name : campaign.mediaName}
                </Button>
                {campaignEditable && (
                  <IconButton
                    onClick={() => setConfirmationOpen(true)}
                    color="secondary"
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                )}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {campaign.status === "CANCELADA" && (
            <Button
              color="primary"
              onClick={() => restartCampaign()}
              variant="outlined"
            >
              {i18n.t("campaigns.dialog.buttons.restart")}
            </Button>
          )}
          {campaign.status === "EM_ANDAMENTO" && (
            <Button
              color="primary"
              onClick={() => cancelCampaign()}
              variant="outlined"
            >
              {i18n.t("campaigns.dialog.buttons.cancel")}
            </Button>
          )}
          {!attachment && !campaign.mediaPath && campaignEditable && (
            <Button
              color="primary"
              onClick={() => attachmentFile.current.click()}
              disabled={isSubmitting}
              variant="outlined"
            >
              {i18n.t("campaigns.dialog.buttons.attach")}
            </Button>
          )}
          <Button
            onClick={handleClose}
            color="secondary"
            disabled={isSubmitting}
            variant="outlined"
          >
            {i18n.t("campaigns.dialog.buttons.close")}
          </Button>
          {(campaignEditable || campaign.status === "CANCELADA") && (
            <Button
              type="submit"
              color="primary"
              disabled={isSubmitting}
              variant="contained"
              className={classes.btnWrapper}
            >
              {campaignId
                ? `${i18n.t("campaigns.dialog.buttons.edit")}`
                : `${i18n.t("campaigns.dialog.buttons.add")}`}
              {isSubmitting && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          )}
        </DialogActions>
      </Form>
    );
  }}
</Formik>

      </Dialog>
    </div>
  );
};

export default CampaignModal;
